import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Gallery from '../components/Gallery'

export const DynamicPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

DynamicPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DynamicPage = ({ data }) => {
  const { markdownRemark: page } = data
  return (
    <Layout title={page.frontmatter.titolo}>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${page.frontmatter.immagine.childImageSharp.fluid.src})`,
        }}
      >
        <style dangerouslySetInnerHTML={{ __html: `.pagehtml strong, .pagehtml b { color: ${page.frontmatter.colore || '#222222'} !important; }`}} />
        <h1
          className="has-text-weight-bold is-size-1 main-title"
          style={{
            color: '#2fbaff',
            padding: '1rem',
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          {page.frontmatter.titolo}
        </h1>
      </div>
      <div className="pagehtml">
        <DynamicPageTemplate
          contentComponent={HTMLContent}
          content={page.html}
        />
        {Array.isArray(page.frontmatter.gallery) && page.frontmatter.gallery.length > 0 &&
          <Gallery gallery={page.frontmatter.gallery} />
        }
      </div>
    </Layout>
  )
}

DynamicPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DynamicPage

export const dynamicPageQuery = graphql`
  query dynamicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        titolo
        colore
        immagine {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          immagine {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
